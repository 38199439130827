@media only screen and (max-width:1679px) {
// MAIN PAGE //
    .main__slider-wrapp {
        margin-top: 3.4rem;
        height: 70rem;
    }
    .main__slider .slick-slide {
        height: 70rem;
    }

    .main__slider .slide__block-bg-txt {
        font-size: 10rem;
        line-height: 1;
    }
    .main__slider .slide__block .new__colection {
        top: -7.3rem;
        right: -4.4rem;
    }
    .main__slider .slide__block-content span {
        font-size: 20rem;
        line-height: 24rem;
    }
// END //
// ABOUT US //
    .about__first {
        min-height: 70rem;
    }
// END //
}
@media only screen and (max-width:1439px) {
    .global-wrapper h1,
    .global-wrapper h2 {
        font-size: 3.6rem;
        line-height: 4.8rem;    
    }
    h3 {
        font-size: 2.4rem;
        line-height: 3.1rem;
    }
    .btn {
        max-width: 28.8rem;
    }
// INFOBANER //
    .infobaner__info {
        max-width: 90rem;
    }
    .infobaner__info .round__txt {
        right: -2.7rem;
    }
    .infobaner__info .bg__line2 {
        top: -8.9rem;
        height: 59rem;
    }
    .infobaner__info-content img {
        width: 4rem;
    }
    .infobaner__info-content h3 {
        max-width: 30rem;
    }
    .infobaner__info-content p {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
// END //
// MAIN PAGE //
.main__categoryes-blocks .block .block__content img {
    width: 4rem;
}
// END //
// ABOUT US //
    .about__first {
        min-height: 60rem;
    }
// END //
}
@media only screen and (max-width:1199px) {
// HEADER //
    .header .header__bottom > ul >li {
        font-size: 1rem;
    }
    .header .header__bottom > ul >li.header__menu-toggle ul {
        font-size: 1rem;
    }   
//  END //
// MAIN PAGE //
    .main__categoryes {
        width: 100%;
        max-width: 97rem;
        margin: 0 auto;
    }
    .main__categoryes-blocks .block {
        max-width: 29rem;
        min-height: 40.7rem;
    }
    .main__categoryes-blocks .block .block__corner {
        width: 29rem;
        height: 29rem;
    }
    .main__new {
        width: 100%;
        max-width: 85rem;
        margin: 0 auto;
    }
    .main__new-slider.no-slider {
        flex-wrap: wrap;
    }
    .main__new-slider.no-slider > div {
        margin-bottom: 2.6rem;
    }
    .main__new-slider.no-slider > div:not(:last-child) {
        margin-right: 0;
    }
    .main__new-slider.no-slider > div:not(:nth-child(3n)) {
        margin-right: 2.6rem;
    }
    .main__insta {
        width: 100%;
        max-width: 99rem;
        margin: 0 auto;
    }
    .main__insta .main__insta-photos .photos__left {
        width: 100%;
        max-width: 55.5rem;
    }
    .main__insta .main__insta-photos .photos__left div {
        width: 18rem;
        height: 18rem;
    }
    .main__insta .main__insta-photos .photos__right div {
        width: 40rem;
        height: 36.5rem;
    }
// END //
// CATALOG //
    .catalog h1 {
        margin: 3.5rem 0;
        font-size: 3.6rem;
        line-height: 4.8rem;   
    }
    .catalog__main-filters {
        margin-right: 1.5rem;
    }
    .catalog__main-products .product {
        max-width: 22rem;
    }
    .catalog__main-products .product:not(:nth-of-type(3n)) {
        margin-right: 1.5rem;
    }
// END //
// SINGLE PRODUCT //
    .single__product-video {
        height: 55rem;
    }
    .single__product-more {
        width: 100%;
        max-width: 85rem;
        margin: 0 auto;
    }
// END //
// MODAL WINDOW //
    .modal__window.modal__product {
        padding: 2rem!important;
    }
    .modal__window.modal__product h2 {
        margin-bottom: 2rem;
    }
    .modal__window.modal__product .info__count {
        flex-wrap: wrap;
        max-width: 33rem;
    }
    .modal__window.modal__product .info__count .size__dropdown {
        margin-right: 0;
    }
    .modal__window.modal__product .info__count .info__count-size {
        margin-top: 2rem;
    }
// END //
// ABOUT US //
    .about__first {
        min-height: 50rem;
    }
// END //
// RESERVE //
    .reserve h1 {
        text-align: center;
    }
    .reserve__products {
        width: 100%;
        max-width: 79.5rem;
        margin: 0 auto;
    }
    .reserve__products .reserve__product {
        margin: 0 0 1.5rem 0;
    }
    .reserve__products .reserve__product:not(:nth-child(4n)) {
        margin-right: 0;
    }
    .reserve__products .reserve__product:not(:nth-child(3n)) {
        margin-right: 1.5rem;
    }
// END //
// FAVORITES //
    .favorites h1 {
        text-align: center;
    }
    .favorites__products {
        width: 100%;
        max-width: 79.5rem;
        margin: 0 auto;
    }
    .favorites__products .favorites__product {
        margin: 0 0 1.5rem 0;
    }
    .favorites__products .favorites__product:not(:nth-child(4n)) {
        margin-right: 0;
    }
    .favorites__products .favorites__product:not(:nth-child(3n)) {
        margin-right: 1.5rem;
    }
// END //
}
@media only screen and (max-width:991px) {
// INFOBANER //
    .infobaner {
        padding: 2rem 0;
        min-height: auto;
    }
    .infobaner__info {
        max-width: 72rem;
        padding: 3rem;
    }
    .infobaner__info .round__txt {
        width: 9rem;
        height: 9rem;
        top: 0;
        right: 0;
    }
// END //
// PRODUCT SLIDER //
    .product__slider .slick-prev {
        left: -3.4rem;
    }
    .product__slider .slick-next {
        right: -3.4rem;
    }
// END //
// PRODUCT //
    .product {
        max-width: 20.5rem;
    }
    .product__img-wrap {
        height: 27rem;
        margin-bottom: 1rem
    }
    .product__img-wrap .product__delete {
        width: 4rem;
        height: 4rem;
        top: .5rem;
        right: .5rem;
    }
    .product__img-wrap .product__delete svg {
        width: 1.4rem;
    }
    .product__reserve-time {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
    .product__reserve-charact {
        margin-bottom: 1rem;
    }
    .product__reserve-charact .charact__block p {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
    .product__sizes-like .size {
        max-width: 16rem;
    }
    .product__sizes-like .size *:not(:nth-child(7n)) {
        margin-right: 0;
    }
    .product__sizes-like .size *:not(:nth-child(5n)) {
        margin-right: 1rem;
    }
    .product__dropdowns .size__dropdown {
        max-width: 9.3rem;
        padding: .5rem;
    }
    .product__colors {
        margin-bottom: 1rem;
    }
    .product__desc {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    .product__price {
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-bottom: 1rem;
    }
    .product__btns a {
        max-width: 9.3rem;
        padding: 1.4rem .5rem;
    }
// END //
// HEADER //
    .header {
        border-bottom-color: #382B25;
    }
    .header .header__top {
        padding: .5rem 1.6rem;
        justify-content: flex-start;
    }
    .header .header__top .header__top-contacts,
    .header .header__top ul li:nth-of-type(1),
    .header .header__top ul li:nth-of-type(2),
    .header .header__top ul li:nth-of-type(3) {
        display: none;
    }
    .header .header__top ul {
        margin-left: auto;
    }
    .header .header__top ul li:not(:last-of-type) {
        margin-right: 2.15rem;
    }
    .header .header__bottom {
        display: none;
    }
    .header .find__block {
        top: 3.4rem;
        padding: 2rem 0;
    }
    .header .find__block .find__form-inp,
    .header .find__block .find__form-inp::placeholder {
        font-size: 3.1rem;
        line-height: 4.8rem;
    }
//  END //
// MAIN PAGE //
    .main__slider .slide__block-bg-txt {
        font-size: 6rem;
    }
    .main__slider .slide__block .new__colection {
        top: -10.3rem;
        right: -7.4rem;
    }
    .main__slider .slide__block-content span {
        font-size: 14rem;
        line-height: 18rem;    
    }
    .main__categoryes {
        padding: 3rem 1.6rem 2.5rem;
    }
    .main__categoryes h2 {
        margin-bottom: 2rem;
        text-align: center;
    }
    .main__categoryes-blocks {
        width: 100%;
        max-width: 72rem;
        margin: 0 auto;
    }
    .main__categoryes-blocks .block {
        max-width: 23rem;
        min-height: 23rem;
        margin-bottom: 1.5rem;
    }
    .main__categoryes-blocks .block:not(:nth-child(3n)) {
        margin-right: 1.5rem;
    }
    .main__categoryes-blocks .block .block__corner {
        width: 23rem;
        height: 23rem;
    }
    .main__categoryes-blocks .block .categoryes__more {
        font-size: 2rem;
        line-height: 3.1rem;
    }
    .main__new {
        max-width: 72rem;
        padding-bottom: 4rem;
    }
    .main__new h2 {
        text-align: center;
        margin-bottom: 2rem;
    }
    .main__new-slider {
        margin-bottom: 3rem;
    }
    .main__new-slider.no-slider > div {
        margin-bottom: 1.5rem;
    }
    .main__new-slider.no-slider > div:not(:nth-child(3n)) {
        margin-right: 1.5rem;
    }
    .main__insta {
        padding: 3rem 1.6rem 3.5rem 1.6rem;
    }
    .main__insta h2 {
        margin-bottom: 2rem;
        text-align: center;
    }
    .main__insta-photos {
        width: 100%;
        max-width: 72rem;
        margin: 0 auto;
    }
    .main__insta .main__insta-photos .photos__left {
        max-width: 43.5rem;
    }
    .main__insta .main__insta-photos .photos__left div {
        width: 14rem;
        height: 14rem;
    }
    .main__insta-photos .photos__right .white__corner {
        width: 23.5rem;
        height: 23.5rem;
    }
    .main__insta .main__insta-photos .photos__right div {
        width: 28.5rem;
        height: 28.5rem;
    }
// END //
// RESULTS //
    .results__wrap {
        padding-top: 7.3rem;
    }
    .results h1 {
        font-size: 3.6rem;
        line-height: 4.8rem;
    }
// END //
// CATALOG //
    .filters__mobile-wrap {
        display: block;
    }
    .catalog {
        margin-top: 4.9rem;
        margin-bottom: 4rem;
    }
    .catalog h1 {
        margin: 2rem 0;
    }
    .catalog__main {
        flex-direction: column;
    }
    .catalog__main-filters {
        margin-right: 0;
        margin-bottom: 2rem;
    }
    .filters .filters__dropdown {
        margin-bottom: .5rem;
        padding: 1.8rem 1.5rem;
    }
    .filters .filters__mobile-btn {
        display: block;
    }
    .catalog__main-filters .filters__lists {
        display: none;
    }
    .catalog__main-products {
        margin: 0 auto;
        justify-content: center;
    }
    .catalog__main-products .product {
        margin: 0 0 1.5rem 0;
    }
// END //
// CART //
    .cart {
        padding-top: 4.7rem;
        padding-bottom: 4rem;
    }
    .cart h1 {
        margin: 2rem 0;
        font-size: 3.6rem;
        line-height: 4.8rem;
    }
    .cart__products-product .cart__name {
        max-width: 16rem;
    }
    .cart__products-product .cart__config {
        max-width: 12rem;
    }
    .cart__products-product .cart__counter {
        max-width: 16.6rem;
    }
// END //
// SINGLE PRODUCT //
    .single__product {
        margin-top: 4.9rem;
        margin-bottom: 4rem;
    }
    .single__product-main {
        margin-top: 2rem;
        flex-wrap: wrap;
    }
    .single__product-main .slider {
        margin: 0 auto;
    }
    .single__product-main .info {
        margin: 2rem auto 0 auto;
    }
    .single__product-main .info h2 {
        margin: 0 0 1rem 0;
    }
    .single__product-main .info__prices {
        margin-bottom: 1rem;
    }
    .single__product-main .info__subttl a {
        margin-right: 0;
    }
    .single__product-main .info__colors {
        margin-bottom: 2rem;
    }
    .single__product-desc {
        padding-top: 4rem;
        margin-bottom: 2rem;
    }
    .single__product-video {
        height: 45rem;
    }
    .single__product-more {
        max-width: 72rem;
        padding-bottom: 4rem;
    }
    .single__product-more h2 {
        text-align: center;
        margin-bottom: 2rem;
    }
// END //
// MODAL WINDOWS //
    .modal__window {
        padding: 3rem!important;
    }
    .modal__window h2 {
        font-size: 2.4rem;
        line-height: 3.6rem;
        margin-bottom: 1rem;
    }
    .modal__window-table {
        border-spacing: 0 2rem;
        margin-bottom: 0;
    }
    .modal__window-table th {
        width: 18rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .modal__window-table td {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .modal__window-desc {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .modal__window-table-wrap {
        width: 100%;
        max-width: 80rem;
        overflow-x: auto;
    }
    .modal__window-table {
        width: 80rem;
    }
// END //
// SINGLE POST //
    .post {
        margin-top: 4.8rem;
        margin-bottom: 4rem;
    }
    .post__content {
        margin-top: 1.5rem;
    }
    .post__content h1 {
        font-size: 3.6rem;
        line-height: 4.6rem;
        margin-bottom: 2.5rem;
    }
    .post__content h2 {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-bottom: 2.5rem;
    }
    .post__content h3 {
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 1.5rem;
    }
    .post__content p {
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-bottom: 2.5rem;
    }
    .post__content ol,
    .post__content ul {
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-bottom: 2.5rem;
    }
    .post__content ol li ol li ol,
    .post__content ol li ol li ul,
    .post__content ol li ul li ol,
    .post__content ol li ul li ul {
        margin: 2rem 0 2rem 1.5rem;
    }
    .post__content img {
        margin-bottom: 4rem;
    }
    .post__content blockquote {
        padding: 4.5rem;
    }
    .post__content blockquote * {
        font-size: 2.1rem;
        line-height: 2.8rem;
    }
    .post__content .align-left {
        max-width: 30rem;
    }
    .post__content .align-right {
        max-width: 30rem;
    }
// END //
// DELIVERY AND PAYMENT //
    .delivery_and-payment {
        margin-bottom: 4rem;
    }
// END //
// ABOUT US //
    .about__first {
        margin-bottom: 4rem;
        min-height: 40rem;
    }
    .about__first-info {
        margin-top: 3.3rem;
    }
    .about__info {
        margin-bottom: 4rem;
    }
    .about__info h2 {
        margin-bottom: 2rem;
    }
    .about__info-blocks {
        margin-bottom: 4rem;
    }
    .about__info-blocks .info__block:not(:last-child) {
        margin-right: 1.5rem;
    }
    .about__info-experience {
        margin-bottom: 4rem;
    }
    .about__info-experience .experience__wrap {
        height: 65rem;
    }
    .about__info-experience .experience__block {
        padding: 4rem;
    }
    .about__info-experience .experience__block p {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    .about__info-price1 {
        margin-bottom: 4rem;
    }
    .about__info-price1 .price1__info p {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    .about__info-price1 .price1__info p:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    .about__info-price2 h2 {
        margin-bottom: 2rem;
    }
    .about__info-price2 p {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    .about__info-price2 p:not(:last-child) {
        margin-bottom: 1.5rem;
    }
// END //
// RESERVE //
    .reserve {
        margin-top: 4.8rem;
        margin-bottom: 2.5rem;
    }
    .reserve h1 {
        margin: 2rem 0;
    }
    .reserve__products {
        max-width: 64.5rem;
    }
// END //
// FAVORITES //
    .favorites {
        margin-top: 4.8rem;
        margin-bottom: 2.5rem; 
    }
    .favorites h1 {
        margin: 2rem 0;
    }
    .favorites__products {
        max-width: 64.5rem;
    }
// END //
}
@media only screen and (max-width:767px) {
// MAIN PAGE //
    .main__slider .slide__block-bg-txt {
        font-size: 4rem;
    }
    .main__slider .slide__block-content span {
        font-size: 12rem;
        line-height: 14rem;
    }
    .main__categoryes-blocks {
        max-width: 47.5rem;
    }
    .main__categoryes-blocks .block:not(:nth-child(3n)) {
        margin-right: 0;
    }
    .main__categoryes-blocks .block:not(:nth-child(even)) {
        margin-right: 1.5rem;
    }
    .main__new {
        max-width: 48rem;
    }
    .main__new-slider.no-slider {
        justify-content: center;
    }
    .main__new-slider.no-slider > div:not(:nth-child(3n)) {
        margin-right: 0;
    }
    .main__new-slider.no-slider > div:not(:nth-child(2n)) {
        margin-right: 1.5rem;
    }
    .main__insta-photos {
        flex-direction: column;
        max-width: 28.5rem;
    }
    .main__insta .main__insta-photos .photos__left {
        max-width: 29rem;
    }
    .main__insta-photos .photos__left div:not(:nth-of-type(3n)) {
        margin-right: 0;
    }
    .main__insta-photos .photos__left div:not(:nth-of-type(even)) {
        margin-right: .5rem;
    }
// END //
// CATALOG //
    .catalog__main-products {
        max-width: 45.5rem;
    }
    .catalog__main-products .product:not(:nth-of-type(3n)) {
        margin-right: 0;
    }
    .catalog__main-products .product:not(:nth-of-type(even)) {
        margin-right: 1.5rem;
    }
// END //
// CART //
    .cart__products-product-wrap {
        padding: 1.5rem 0;
    }
    .cart__products {
        margin-bottom: 2rem;
    }
    .cart__products-product {
        flex-wrap: wrap;
        width: 100%;
        max-width: 28rem;
        margin: 0 auto;
        justify-content: center;
    }
    .cart__products-product .cart__img-wrap {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .cart__products-product .cart__name {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
        text-align: center;
    }
    .cart__products-product .cart__config {
        margin-right: 0;
    }
    .cart__products-product .cart__price {
        margin-right: 0;
        margin-left: auto;
    }
    .cart__products-product .cart__counter {
        margin-right: 0;
    }
    .cart__products-product .cart__price-all {
        margin-right: 0;
        margin-left: auto;
    }
    .cart__products-product .cart__delete {
        margin-left: auto;
    }
    .cart__total {
        margin-bottom: 1.5rem;
    }
// END //
// SINGLE PRODUCT //
    .single__product-desc {
        padding-top: 2rem;
        flex-wrap: wrap;
    }
    .single__product-desc div {
        max-width: 100%;
    }
    .single__product-desc div:first-child {
        margin-right: 0;
    }
    .single__product-video {
        height: 40rem;
    }
    .single__product-more {
        max-width: 48rem;
    }
// END //
// FOOTER //
    .footer .footer__top {
        padding: 2rem 1.5rem;
        flex-direction: column;
    }
    .footer .footer__top .footer__top-logo {
        margin-bottom: 2rem;
    }
    .footer .footer__top .footer__top-contacts {
        flex-direction: column;
    }
    .footer .footer__top .footer__top-contacts *:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1.5rem;
    }
    .footer .footer__bottom {
        flex-direction: column;
    }
    .footer .footer__bottom ul {
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .footer .footer__bottom ul li:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 1.5rem;
    } 
// END //
// ABOUT US //
    .about__info-experience .experience__block {
        padding: 3rem 1.5rem;
    }
// END //
// RESERVE //
    .reserve__products {
        max-width: 42.5rem;
    }
    .reserve__products .reserve__product:not(:nth-child(3n)) {
        margin-right: 0;
    }
    .reserve__products .reserve__product:not(:nth-child(2n)) {
        margin-right: 1.5rem;
    }
// END //
// FAVORITES //
    .favorites__products {
        max-width: 42.5rem;
    }
    .favorites__products .favorites__product:not(:nth-child(3n)) {
        margin-right: 0;
    }
    .favorites__products .favorites__product:not(:nth-child(2n)) {
        margin-right: 1.5rem;
    }
// END //
}
@media only screen and (max-width:575px) {
    .global-wrapper h1,
    .global-wrapper h2 {
        font-size: 2.4rem;
        line-height: 3.6rem;    
    }
    h3 {
        font-size: 2.1rem;
        line-height: 2.6rem;
    }
// PRODUCT //
    .product {
        max-width: 25.5rem;
    }
    .product__img-wrap {
        height: 34rem;
    }
    .product__dropdowns .size__dropdown {
        max-width: 12.3rem;
        padding: 1.5rem 1rem; 
    }
    .product__btns a {
        max-width: 12.3rem;
    }
// END //
// INFOBANER //
    .infobaner__info {
        padding: 1.6rem;
    }
    .infobaner__info .round__txt {
        width: 6rem;
        height: 6rem;
        top: .5rem;
        right: .5rem;
    }
    .infobaner__info-content {
        margin-bottom: 0;
    }
    .infobaner__info-content p {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
// END //
// HEADER //
    .header .find__block .find__form-btns {
        flex-direction: column;
    }
    .header .find__block .find__form-btns a {
        order: 2;
        margin-right: 0;
        margin-top: 2rem;
    }
// END //
// MAIN PAGE //
    .main__slider-wrapp,
    .main__slider .slick-slide {
        height: 53.5rem;
    }
    .main__slider .slide__block-bg-txt {
        display: none;
    }
    .main__slider .slide__block-content span {
        font-size: 10rem;
        line-height: 12rem;
        margin-bottom: 2rem;
    }
    .main__categoryes {
        padding-bottom: 3.5rem;
    }
    .main__categoryes-blocks {
        max-width: 28.5rem;
    }
    .main__categoryes-blocks .block {
        max-width: 14rem;
        min-height: 14rem;
        margin-bottom: .5rem;
    }
    .main__categoryes-blocks .block:not(:nth-child(even)) {
        margin-right: .5rem;
    }
    .main__categoryes-blocks .block .block__corner {
        width: 14.3rem;
        height: 14.3rem;
    }
    .main__categoryes-blocks .block .categoryes__more {
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding-right: 2.4rem;
    }
    .main__categoryes-blocks .block .categoryes__more::after {
        width: 2rem;
        top: 3px;
    }
    .main__categoryes-blocks .block .block__content img {
        width: 3rem;
        margin-bottom: 1rem;
    }
    .main__categoryes-blocks .block .block__content h3 {
        margin-bottom: 0;
    }
    .main__categoryes-blocks .block .block__content span {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .main__new-slider.no-slider > div:not(:nth-child(2n)) {
        margin-right: 0;
    }
    .main__new-more {
        font-size: 1.6rem;
        padding-right: 4rem;
    }
    .main__insta-photos .photos__right .white__corner {
        width: 20rem;
        height: 20rem;
    }
    .main__insta-photos .photos__right-more {
        font-size: 1.6rem;
        padding-right: 4rem;
    }
// END //
// CATALOG //
    .catalog h1 {
        font-size: 2.4rem;
        line-height: 3.1rem;
    }
    .catalog__main-products .product:not(:nth-of-type(even)) {
        margin-right: 0;
    }
    .catalog__main-products .product {
        max-width: 25.5rem;
    }
// END //
//  FOOTER //
    .footer .footer__bottom .footer__bottom-copy * {
        font-size: 1.4rem;
    }
//  END //
// CART //
    .cart h1 {
        font-size: 2.4rem;
        line-height: 3.1rem;
    }
    .cart__total {
        justify-content: center;
    }
    .cart__btns {
        flex-direction: column;
    }
    .cart__btns a {
        order: 2;
    }
    .cart__btns button {
        margin-left: 0;
        margin-bottom: 1.5rem;
    }
// END //
// SINGLE PRODUCT //
    .single__product-main .slider__navigation-wrap {
        width: 4rem;
        height: 4rem;
    }
    .single__product-main .slider__navigation {
        max-width: 4rem;
        height: 13.8rem;
    }
    .single__product-main .slider__navigation .slick-prev,
    .single__product-main .slider__navigation .slick-next {
        width: 4rem;
        height: 4rem;
    }
    .single__product-main .slider__navigation .slick-prev {
        top: -4.2rem;
    }
    .single__product-main .slider__navigation .slick-next {
        bottom: -4.2rem;
    }
    .single__product-main .slider {
        max-width: 29rem;
    }
    .single__product-main .slider__single {
        width: 22rem;
        height: 22rem;
    }
    .single__product-main .info h2 {
        font-size: 3.1rem;
        line-height: 4.1rem;
    }
    .single__product-main .info__prices {
        font-size: 1.8rem;
        line-height: 2.1rem;
    }
    .single__product-main .info__count {
        width: 100%;
        max-width: 33rem;
        flex-wrap: wrap;
    }
    .size__dropdown {
        margin-right: 0;
    }
    .single__product-main .info__count-size {
        margin-top: 1rem;
    }
    .single__product-main .info__prices .old-price {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    .single__product-desc div h4 {
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
    }
    .single__product-desc div p {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    .single__product-video {
        height: 30rem;
    }
// END //
// SINGLE POST //
    .post__content blockquote {
        padding: 2rem;
    }
    .post__content blockquote::before {
        top: -2rem;
        left: 2rem;
        width: 2.8rem;
    }
    .post__content blockquote * {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    .post__content .align-left,
    .post__content .align-right {
        float: unset;
        margin: 0;
    }
// END //
// ABOUT US //
    .about__first {
        min-height: 35rem;
    }
    .about__info h2 {
        text-align: center;
    }
    .about__info-blocks .info__block-wrap {
        margin: 0 auto 1.5rem auto;
    }
    .about__info-experience .experience__wrap {
        display: none;
    }
    .about__info-experience .experience__block {
        margin-left: 0;
    }
    .about__info-experience .experience__block p {
        text-align: center;
    }
    .about__info-blocks {
        flex-direction: column;
        align-items: center;
    }
    .about__info-blocks .info__block:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1.5rem;
    }
    .about__info-price1 {
        flex-direction: column;
    }
    .about__info-price1 .price1__wrap {
        margin-bottom: 1.5rem;
        max-width: 30rem;
    }
    .about__info-price1 .price1__info {
        margin-right: 0;
        max-width: 100%;
        order: 2;
    }
// END //
// RESERVE //
    .reserve__products {
        justify-content: center;
    }
    .reserve__products .reserve__product:not(:nth-child(2n)) {
        margin-right: 0;
    }
// END //
// FAVORITES //
    .favorites__products {
        justify-content: center;
    }
    .favorites__products .favorites__product:not(:nth-child(2n)) {
        margin-right: 0;
    }
// END //
}
@media only screen and (max-width:424px) {
    .inp {
        padding: 2.5rem 2rem;
    }
// BREADCRUMBS //
    .bredacrumbs {
        overflow-x: auto;
    }
    .bredacrumbs a {
        white-space: nowrap;
    }
// END //
// HEADER //
    .header .find__block .find__form-inp,
    .header .find__block .find__form-inp::placeholder {
        font-size: 2.4rem;
        line-height: 3.1rem;
    }
// END //
// MAIN PAGE //
    .main__slider .slick-dots {
        bottom: 1.6rem;
    }
    .main__slider .slick-dots li:not(:last-of-type) {
        margin-right: 2rem;
    }
    .main__slider .slide__block .new__colection {
        top: 26rem;
        right: .5rem;
    }
    .main__slider .slide__block {
        padding: 0 1.6rem;
    }
    .main__slider .slide__block-content {
        align-items: flex-start;
    }
    .main__slider .slide__block-content span {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 21rem;
        word-break: break-all;
    }
    .main__slider .slide__block-content a {
        position: relative;
        z-index: 2;
    }
// END //
// CATALOG //
    .catalog__main-filters {
        max-width: 100%;
    }
    .catalog__main-products .products__pagination {
        margin-top: 1.5rem;
    }
// END //
// SINGLE PRODUCT //
    .single__product-main .info__btns-cart {
        max-width: 17rem;
        height: 5rem;
        padding: 1.4rem .5rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .single__product-video {
        height: 23rem;
    }
// END //
// AUTORIZATION FORM //
    .modal__window.modal__authorization h3 {
        font-size: 2.4rem;
        line-height: 3.6rem;
    }
    .modal__window.modal__authorization a {
        font-size: 1.1rem;
    }
    .modal__window.modal__authorization .authorization__form-wrap,
    .modal__window.modal__authorization .forgotpass__form-wrap {
        max-width: 29rem;
    }
    .modal__window.modal__authorization .authorization__form-wrap h3 {
        margin-bottom: 2rem;
    }
    .modal__window.modal__authorization form .inp:first-child {
        margin-bottom: 2rem;
    }
    .modal__window.modal__authorization form > div label {
        font-size: 1.1rem;
    }
// END //
}
@media only screen and (max-width:374px) {
// PAGINATION //
    .pagination * {
        width: 4rem;
        height: 4rem;
        padding: 1rem;
    }
    .pagination *:not(:last-child) {
        margin-right: .5rem;
    }
// END //
// SINGLE PRODUCT //
    .single__product-main .info__prices {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    .single__product-main .info__prices .old-price {
        margin-left: 1rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .single__product-main .info__count {
        max-width: 28rem;
    }
    .single__product-main .info__count .cart__counter {
        margin-right: 0;
        margin-bottom: 1rem;
    }
// END //
// MODAL WINDOW //
.modal__window.modal__product .info__count {
    max-width: 28rem;
}
// END //
}


@media only screen and (min-width:992px) {
// MOBILE MENU //
    .mobile__menu {
        display: none;
    }
// END //
// HEADER //
    .header .header__top .header__top-hamburger,
    .header .header__top .header__top-logo-mobile {
        display: none;
    }
// END //
// CATALOG //
    .filters .filters__lists-list .list__content.size label:hover span {
        border-color: #EA4C89;
        background-color: #EA4C89;
        color: #fff;
    }
    .filters .filters__lists-list .list__content label span:hover {
        color: #EA4C89;
    }
// END //
}
@media only screen and (max-height:812px) {
// MOBILE MENU //
    .mobile__menu-wrap {
        height: 100vh;
    }
    .mobile__menu-wrap .blur-line {
        display: block;  
    }
    .filters__mobile-wrap .blur-line {
        display: block;
    }
    .mobile__menu {
        height: calc(100vh - 3rem);
        overflow-y: scroll;
    }
// END //
}